import logo from './alionft-logo.svg'
import cooldevsLogo from './cooldevs-logo.svg'
import ourstory from './our-story.svg'
import featuredDevs from './featured-devs.svg'
import roadMap from './roadmap.svg'
import faq from './FAQ.svg'
import nft1 from './nfts/el-barbas.png'
import nft11 from './nfts/el-barbas2.png'
import nft2 from './nfts/firey-sue.png'
import dino from './dinosaur-alio.svg'
import './App.css'
import Button from "react-bootstrap/Button"
import Navbar from "react-bootstrap/Navbar"
import Nav from "react-bootstrap/Nav"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Accordion from "react-bootstrap/Accordion"
// import NavDropdown from 'react-bootstrap/NavDropdown'
// import Cards from "react-bootstrap/Card"

import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-icons/font/bootstrap-icons.css';

function App() {
  window.addEventListener('scroll',(e)=>{
    const nav = document.querySelector('.navbar');
    
    if(window.pageYOffset>0){
      nav.classList.add("add-shadow");
    }else{
      nav.classList.remove("add-shadow");
    }
  });

  return (
    <div className="App">
      <header>
        <Navbar bg="white" expand="lg" fixed="top">
          <Container>
            <Navbar.Brand href="#devs">
              <img
                src={logo}
                width="80"
                height="80"
                className="d-inline-block align-top"
                alt="Alio NFT"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="m-auto">
                <Nav.Link href="#devs">DEVS</Nav.Link>
                <Nav.Link href="#our-story">OUR STORY</Nav.Link>
                <Nav.Link href="#featured-devs">FEATURED DEVS</Nav.Link>
                <Nav.Link href="#roadmap">ROADMAP</Nav.Link>
                <Nav.Link href="#faq">FAQ</Nav.Link>
              </Nav>
              <Button variant="outline-dark">
                CONNECT WALLET
              </Button>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
      <main>
        <Container style={{ marginTop: "12em" }}>
          {/* INTRO */}
          <Row className="my-5" id="devs">
            <Col sm={6} className="mt-5">
              <img
                src={cooldevsLogo}
                width="500"
                height="71"
                className="d-inline-block align-top"
                alt="COOLDEVS"
              />
              <p className='collection-description'>
                Cool Devs are a collection of 80 NFTs that are part of the ALIO IT collection with an ERC721 Token Standard on the Ethereum blockchain.
              </p>
              <Row className="mt-5 mb-2">
                {/* <Col>
                  <Button variant="primary" size="lg" className="mint-btn">MINT (COMING SOON)</Button>
                </Col> */}
                <Col>
                  <Button variant="secondary" size="lg" className="opensea-btn" href="https://opensea.io/collection/cooldevs" target="_blank">OPENSEA</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="outline-dark" size="lg" className="faq-btn" href="#faq">WHAT ARE NFT'S?</Button>
                </Col>
              </Row>
            </Col>
            <Col sm={6} style={{ textAlign: "center" }}>
              <img
                src={nft1}
                width="462"
                height="444"
                className="d-inline-block align-top"
                alt="El Barbas"
              />
            </Col>
          </Row>

          {/* OUR STORY */}
          <Row className="mb-5" style={{ marginTop: "12em" }} id="our-story">
            <Col sm={6} style={{ textAlign: "center" }}>
              <img
                src={dino}
                width="662"
                height="444"
                className="d-inline-block align-top"
                alt="Dinosaur"
              />
            </Col>
            <Col sm={6}>
              <img
                src={ourstory}
                width="500"
                height="71"
                className="d-inline-block align-top"
                alt="Our Story"
              />
              <p className='collection-description'>
                Cool Devs are our effort to communicate to the world how truely unique and valuable the development culture is, and with your help make this community stronger each NFT that is purchased will be exchangeable in the future for IT consulting and merchandising related to Cool Devs.
              </p>
              <Row className="mt-5 mb-2">
                <Col>
                  <Button variant="primary" size="lg" className="mint-btn" href="https://discord.com/channels/911651676845387796/911651677306773546" target="_blank">JOIN OUR DISCORD</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button variant="secondary" size="lg" className="instagram-btn" href="https://www.instagram.com/alioit_solutions/" target="_blank">INSTAGRAM</Button>
                </Col>
                <Col>
                  <Button size="lg" className="opensea-btn" href="https://twitter.com/alio_it" target="_blank">TWITTER</Button>
                </Col>
              </Row>
            </Col>
          </Row>

          {/* FEATURED */}
          <Row className="mb-5" style={{ marginTop: "12em" }} id="featured-devs">
            <Col sm={6}>
              <img
                src={featuredDevs}
                width="700"
                height="71"
                className="d-inline-block align-top"
                alt="Featured Devs"
              />
            </Col>
            <Col sm={6} style={{ textAlign: "right" }}>
              <a className="btn carousel-btn carousel-left-btn mb-3" href="#carouselExampleIndicators2" role="button" data-slide="prev">
                <i className="bi bi-arrow-left"></i>
              </a>
              <a className="btn carousel-btn mb-3" href="#carouselExampleIndicators2" role="button" data-slide="next">
                <i className="bi bi-arrow-right"></i>
              </a>
            </Col>
            <Col sm={12} className="mt-4">
              <div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                  <div className="carousel-item active">
                    <div className="row">
                      <div className="col-md-4 mb-3">
                        <div className="card">
                          <img className="img-fluid" alt="100%x280" src={nft2}></img>
                          <div className="card-body">
                            <h4 className="card-title">Firey Sue</h4>
                            <p className="card-text">Flamboyant and truely dedicated to her work, cannot stand, as many of us, a compilation error. She has a technical project manager super power!</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4 mb-3">
                        <div className="card">
                          <img className="img-fluid" alt="100%x280" src={nft11}></img>
                          <div className="card-body">
                            <h4 className="card-title">El Barbas</h4>
                            <p className="card-text">Loves to chill and code with some good house beats and sometimes gets a little old school with some grunge. His super power is CSS and styling which you can say is rare for a developer :)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          {/* ROADMAP */}
          <Row className="mb-5" style={{ marginTop: "12em" }} id="roadmap">
            <Col sm={12} className="mb-5" style={{ textAlign: "center" }}>
              <img
                src={roadMap}
                width="500"
                height="71"
                className="d-inline-block align-top"
                alt="Roadmap"
              />
            </Col>

            <div className="row align-items-center how-it-works d-flex">
              <div className="col-2 text-center bottom d-inline-flex justify-content-center align-items-center">
                <div className="circle font-weight-bold">1</div>
              </div>
              <div className="col-6">
                <h5>LAUNCH</h5>
                <p>We are launching May 31st! Mark your calendar to check out our first collection of 10 memorable Cool Devs.</p>
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner top-right"></div>
              </div>
              <div className="col-8">
                <hr/>
              </div>
              <div className="col-2">
                <div className="corner left-bottom"></div>
              </div>
            </div>

            <div className="row align-items-center justify-content-end how-it-works d-flex">
              <div className="col-6 text-right">
                <h5>More NFTs..</h5>
                <p>Did you miss out on the first launch? No worries we will release our second and third batches every month or so. </p>
              </div>
              <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                <div className="circle font-weight-bold">2</div>
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner right-bottom"></div>
              </div>
              <div className="col-8">
                <hr/>
              </div>
              <div className="col-2">
                <div className="corner top-left"></div>
              </div>
            </div>

            <div className="row align-items-center how-it-works d-flex">
              <div className="col-2 text-center top d-inline-flex justify-content-center align-items-center">
                <div className="circle font-weight-bold">3</div>
              </div>
              <div className="col-6">
                <h5>Special NFTs unlocked</h5>
                <p>If you held on to NFTs from our first batches you can exchange them for some special NFT in turn making you eligible to IT Consulting hours!</p>
              </div>
            </div>

            <div className="row timeline">
              <div className="col-2">
                <div className="corner top-right"></div>
              </div>
              <div className="col-8">
                <hr/>
              </div>
              <div className="col-2">
                <div className="corner left-bottom"></div>
              </div>
            </div>

            <div className="row align-items-center justify-content-end how-it-works d-flex">
              <div className="col-6 text-right">
                <h5>DAO community</h5>
                <p>Our roadmap includes building a DAO to help CoolDevs become a community of web3 developers and entrepreneurs participating in the proliferation and maturity of our token</p>
              </div>
              <div className="col-2 text-center full d-inline-flex justify-content-center align-items-center">
                <div className="circle font-weight-bold">4</div>
              </div>
            </div>

          </Row>

          {/* FAQ */}
          <Row className="mb-5" style={{ marginTop: "12em" }} id="faq">
            <Col sm={12} className="mb-5" style={{ textAlign: "center" }}>
              <img
                src={faq}
                width="160"
                height="71"
                className="d-inline-block align-top"
                alt="FAQ"
              />
            </Col>
            <Col sm={12} className="mt-2">
              <Accordion alwaysOpen>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>What is an NFT?</Accordion.Header>
                  <Accordion.Body>
                    An NFT is a digital asset that represents real-world objects like art, music, in-game items and videos. 
                    They are bought and sold online, frequently with cryptocurrency, and they are generally encoded with 
                    the same underlying software as many cryptos.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>What is a blockchain?</Accordion.Header>
                  <Accordion.Body>
                    A blockchain is a distributed database that is shared among the nodes of a computer network. As a database, 
                    a blockchain stores information electronically in digital format. Blockchains are best known for their crucial role 
                    in cryptocurrency systems, such as Bitcoin, for maintaining a secure and decentralized record of transactions. 
                    The innovation with a blockchain is that it guarantees the fidelity and security of a record of data and generates trust 
                    without the need for a trusted third party.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Where do I buy NFTs?</Accordion.Header>
                  <Accordion.Body>
                    Although NFTs can be expensive, you're paying for more than just a JPEG file. The token gives you ownership rights 
                    to the piece you receive, and you're able to sell your NFTs on marketplaces like OpenSea and Nifty Gateway. Saying 
                    that NFTs are just JPEG files is the equivalent of calling a Google image of Van Gogh's The Starry Night the real thing. 
                    Similar to traditional artwork, the value of NFTs comes from ownership of the "original".
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>What is a wallet?</Accordion.Header>
                  <Accordion.Body>
                    A digital wallet (or electronic wallet) is a financial transaction application that runs on mobile devices. It securely 
                    stores your payment information and passwords. These applications allow you to pay when you're shopping using your device 
                    so that you don't need to carry your cards around. You enter and store your credit card, debit card, or bank account 
                    information and can then use your device to pay for purchases.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header>What is minting?</Accordion.Header>
                  <Accordion.Body>
                    In simple terms, Minting NFT refers to the process of turning a digital file into a crypto collectible or digital asset on 
                    the Ethereum blockchain. The digital item or file is stored in this decentralized database or distributed ledger forever, 
                    and it is impossible to edit, modify, or delete it. As is the process of creating fiat coins, when a manufacturer mints a 
                    physical coin, the process of uploading a specific item onto the blockchain is known as “minting”.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header>Still have questions, who do I contact?</Accordion.Header>
                  <Accordion.Body>
                    info@alioit.com
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </main>
    </div>
  );
}

export default App;
